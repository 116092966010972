.hp-current-offers {
  background-color: #fff;
  padding-block: var(--vertical-space);
  --vertical-space: var(--space-32);
}
@media (min-width: 768px) {
  .hp-current-offers {
    --vertical-space: var(--space-56);
  }
}
.hp-current-offers__list {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}
.hp-current-offers__list li:first-child {
  --bg-color: var(--color-orange-100);
  --main-color: var(--color-orange-1000);
}
.hp-current-offers__list li:nth-child(2) {
  --bg-color: var(--color-blue-200);
  --main-color: var(--color-brand-1100);
}
.hp-current-offers__list li:last-child {
  --bg-color: var(--color-coral-100);
  --main-color: var(--color-coral-900);
}
@media (max-width: 767px) {
  .hp-current-offers__list--mobile-stacked {
    position: relative;
    padding-top: calc(var(--space) * 2);
    gap: 0;
    cursor: pointer;
    --space: 110px;
  }
  .hp-current-offers__list--mobile-stacked li {
    width: 100%;
    box-shadow: 0 -3px 20px 0 rgba(0, 0, 0, 0.15);
  }
  .hp-current-offers__list--mobile-stacked li:first-child {
    position: relative;
    top: 0;
    z-index: 3;
  }
  .hp-current-offers__list--mobile-stacked li:nth-child(2) {
    position: absolute;
    top: var(--space);
    z-index: 2;
  }
  .hp-current-offers__list--mobile-stacked li:last-child {
    position: absolute;
    top: 0;
    z-index: 1;
  }
}
@media (min-width: 1024px) {
  .hp-current-offers__list {
    flex-direction: row;
  }
  .hp-current-offers__list li {
    width: 29.1666666667%;
  }
  .hp-current-offers__list li:last-child {
    width: 41.6666666667%;
  }
}
.hp-current-offers__title {
  color: var(--color-blue-1100) !important;
  margin-block-end: var(--vertical-space);
  text-align: center;
  --title-color: var(--color-blue-1100);
  --title-size: 1rem;
  --title-line: 1rem;
  font-weight: 500;
  font-size: var(--title-size);
  line-height: var(--title-line);
  color: var(--title-color);
  --title-color: var(--color-neutral-1400);
  --title-size: 1.5rem;
  --title-line: 1.35;
  margin-top: var(--space-20);
}
@media (max-width: 1023px) {
  .hp-current-offers__title {
    --title-size: 1.125rem;
  }
}

.offer {
  border-radius: var(--radius-main);
  padding: 1rem;
  background-color: var(--bg-color);
  position: relative;
  display: grid;
  grid-template-rows: 2rem auto auto;
  gap: 1rem;
}
@media (min-width: 768px) {
  .offer {
    gap: 1.5rem;
  }
}
.offer__pill {
  place-self: flex-start flex-end;
  text-transform: uppercase;
  background-color: var(--main-color);
  color: #fff;
  padding: 0.5rem 0.75rem;
  border-radius: 1rem;
  font-weight: 500;
  font-size: var(--fs-sm);
}
.offer__pill:empty {
  visibility: hidden;
}
@media (min-width: 768px) {
  .offer__pill {
    padding-block: 0.375rem;
    font-size: 1rem;
  }
}
.offer__title {
  font-weight: 500;
  font-size: 1.5rem;
}
@media (max-width: 767px) {
  .offer__title {
    font-size: 1rem;
  }
}
.offer__content {
  color: var(--color-blue-1100);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: var(--fs-md);
}
.offer__content--highlight {
  color: var(--main-color);
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.25;
}
.offer__content sup {
  font-size: 0.7em;
}
.offer__content--small {
  display: block;
  font-size: var(--fs-md);
  line-height: 1.35;
}
.offer__content--legal {
  color: var(--color-neutral-1100);
  font-size: 0.75rem;
  line-height: 1.35;
}
@media (min-width: 768px) {
  .offer__content {
    font-size: var(1rem);
  }
  .offer__content--highlight {
    font-size: 2.65rem;
  }
  .offer__content--small {
    font-size: 1rem;
  }
}
.offer__link {
  --pt: var(--space-24);
  color: var(--main-color);
  font-weight: 500;
  text-decoration: none;
  font-size: var(--fs-md);
  place-self: flex-end flex-end;
  line-height: 1.4;
  display: flex;
  gap: 12px;
  padding-top: var(--pt);
}
.offer__link::after {
  font-family: HB Pictos !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f12c";
  font-size: 20px;
}
@media (min-width: 768px) {
  .offer__link {
    --pt: var(--space-40);
  }
}
.offer .tooltip-cta {
  color: inherit;
}